<script setup lang="ts">
const model = defineModel<boolean>();

const localeRoute = useLocaleRoute();
const authStore = useAuthStore(useNuxtApp().$pinia);
const { getPlaces } = useGlobalStore();
const { network } = useAppConfig();

const airports = getPlaces("AIRPORT");
const stations = getPlaces("STATION");
</script>

<template>
  <v-navigation-drawer mobile mobile-breakpoint="md" v-model="model" :scrim="false" location="right" width="500" class="md:hidden">
    <v-list density="compact" class="font-semibold" :opened="['AIRPORT', network.country.toUpperCase()]">
      <!-- <div class="pl-4 text-sm text-gray border-b border-gray-tonal pb-2">
        Parkuj z nami taniej!
      </div> -->

      <v-list-item color="primary" v-for="(item, index) in [{ title: $t('menu.faq'), route: 'faq' }]" :key="index" :to="localeRoute({ name: item.route })">
        {{ item.title }}
      </v-list-item>

      <v-list-group value="AIRPORT">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            {{ $t("menu.parking_airports") }}
          </v-list-item>
        </template>

        <template v-for="(places, country_key) in airports">
          <v-list-group :value="`AIRPORT_` + country_key">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" class="text-primary">
                {{ $t("countries." + country_key) }}
              </v-list-item>
            </template>

            <template v-for="place in places">
              <v-list-item color="primary" :to="localeRoute({ name: 'place', params: { place: place.route } })">
                <v-list-item-title class="font-semibold">
                  {{ place.title }}
                </v-list-item-title>
                <template #append>
                  <div class="w-20 inline-block font-semibold text-center">
                    <span class="bg-orange px-4 py-1 rounded-full text-sm text-black">{{ place.code }}</span>
                  </div>
                </template>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-group>

      <v-list-group value="STATION" v-if="Object.keys(stations).length !== 0">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            {{ $t("menu.parking_stations") }}
          </v-list-item>
        </template>

        <template v-for="(places, country_key) in stations">
          <v-list-group :value="`STATIONS_` + country_key">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" class="text-primary">
                {{ $t("countries." + country_key) }}
              </v-list-item>
            </template>

            <template v-for="place in places">
              <v-list-item color="primary" :to="localeRoute({ name: 'place', params: { place: place.route } })">
                <v-list-item-title class="font-semibold">
                  {{ place.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
      </v-list-group>

      <v-list-item
        color="primary"
        v-for="(item, index) in [
          { title: $t('menu.others_howitworks'), route: 'others-howitworks' },
          { title: $t('menu.others_howtoselect'), route: 'others-howtoselect' },
          { title: $t('menu.others_whywe'), route: 'others-whywe' },
          { title: $t('menu.contact'), route: 'contact' },
        ]"
        :key="index"
        :to="localeRoute({ name: item.route })"
      >
        {{ item.title }}
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="border-t border-gray-tonal" v-if="!authStore.isLoggedIn">
        <v-list-item color="primary" :to="localeRoute({ name: 'bookings-manage' })" :title="$t('navbar.booking_manage')"> </v-list-item>
        <v-list-item color="primary" v-if="authStore.isBooking" @click="authStore.logout()" :title="$t('buttons.logout')" :subtitle="authStore.state?.user?.name" class="text-right mr-4"> </v-list-item>

        <v-list-item color="primary" :to="localeRoute({ name: 'customer-auth-register' })" :title="$t('buttons.register')" :subtitle="$t('navbar.customer_subtitle')"> </v-list-item>
      </div>
      <div class="border-t border-gray-tonal" v-else-if="authStore.isCustomer">
        <v-list-item color="primary" :to="localeRoute({ name: 'customer-bookings' })">
          <v-list-item-title class="font-semibold">
            {{ $t("menu.customer_bookings") }}
          </v-list-item-title>
        </v-list-item>
      </div>

      <div class="p-4 border-t border-gray-tonal flex items-center">
        <!-- <v-btn variant="tonal" color="primary" class=""> Logout </v-btn> -->
        <Locale color="gray" :icon="false" />
        <v-spacer />
        <v-btn :to="localeRoute({ name: 'customer' })" :variant="authStore.isLoggedIn ? 'tonal' : 'outlined'" rounded="xl" :color="authStore.isLoggedIn ? 'primary' : undefined" class="border-gray-tonal">
          <span v-if="authStore.isLoggedIn && authStore.isCustomer" class="truncate max-w-20">
            {{ authStore.state?.user?.name }}
          </span>
          <span v-else-if="authStore.isLoggedIn && authStore.isBooking">
            {{ $t("fields.booking") }}
          </span>
          <span v-else>
            {{ $t("navbar.account") }}
          </span>

          <v-icon end v-if="!authStore.isBooking">
            <v-avatar v-if="authStore.isLoggedIn && !authStore.state?.user?.avatar?.includes('nouserphoto')" size="x-small" class="bg-white">
              <img :src="authStore.state?.user?.avatar" alt="avatar" />
            </v-avatar>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" style="display: block; height: 100%; width: 100%; fill: currentcolor">
              <path d="M16 .7C7.56.7.7 7.56.7 16S7.56 31.3 16 31.3 31.3 24.44 31.3 16 24.44.7 16 .7zm0 28c-4.02 0-7.6-1.88-9.93-4.81a12.43 12.43 0 0 1 6.45-4.4A6.5 6.5 0 0 1 9.5 14a6.5 6.5 0 0 1 13 0 6.51 6.51 0 0 1-3.02 5.5 12.42 12.42 0 0 1 6.45 4.4A12.67 12.67 0 0 1 16 28.7z"></path>
            </svg>
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
