<script lang="ts" setup>
import { mdiAirplane, mdiParking, mdiTrainBus } from "mdi-js-es";
import { useGlobalStore } from "~/stores/global";

const { getPlaces } = useGlobalStore();
const airports = computed(() => getPlaces("AIRPORT"));
const stations = computed(() => getPlaces("STATION"));

const route = useRoute();
const localeRoute = useLocaleRoute();
const authStore = useAuthStore();

const sidebar = ref(false);

const navbarBack = computed(() => (route.meta.navbar_back as string) || undefined);
const navbarText = computed(() => (route.meta.navbar_text as string) || undefined);
const navbarColor = computed(() => (route.meta.navbar_color as string) || undefined);

const { network } = useAppConfig();
</script>

<template>
  <!-- <NavbarTop /> -->

  <v-app-bar
    :absolute="!sidebar"
    :color="sidebar ? 'white' : navbarColor"
    :class="route.meta.navbar_class + ' border-gray-tonal' + (sidebar ? ' border-b' : '')"
  >
    <template #prepend>
      <v-btn
        icon
        v-if="navbarBack == 'place'"
        :to="localeRoute({ name: 'place', params: { place: route.params.place }, hash: '#' + route.params.parking })"
        :color="navbarColor"
        class="md:hidden"
      >
        <v-icon :icon="$vuetify?.icons?.aliases?.prev" />
      </v-btn>
    </template>

    <template #append>
      <v-btn
        icon
        variant="text"
        :color="!sidebar ? navbarText : undefined"
        @click="sidebar = !sidebar"
        class="md:hidden"
      >
        <v-icon>
          <svg
            v-if="sidebar"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style="
              display: block;
              fill: currentColor;
              height: 16px;
              width: 16px;
              stroke: currentcolor;
              stroke-width: 3;
              overflow: visible;
            "
          >
            <path
              d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style="
              display: block;
              fill: none;
              height: 16px;
              width: 16px;
              stroke: currentcolor;
              stroke-width: 3;
              overflow: visible;
            "
          >
            <g fill="none"><path d="M2 16h28M2 24h28M2 8h28"></path></g>
          </svg>
        </v-icon>
      </v-btn>
    </template>

    <div class="container flex items-center">
      <NuxtLink :to="localeRoute({ name: 'index' })" :prefetch="false">
        <img
          :src="
            !sidebar && route.meta.navbar_text == 'white'
              ? '/images/logo/nextpark-white.svg'
              : '/images/logo/nextpark.svg'
          "
          alt="nextpark logo"
          width="150"
          height="50"
          class="h-[36px]"
        />
      </NuxtLink>

      <v-menu elevation="3" v-if="Object.keys(airports).length !== 0">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" :color="navbarText" rounded="xl" class="hidden md:flex ml-4">
            <VIcon start :icon="mdiAirplane" />
            {{ $t("menu.parking_airports") }}
          </v-btn>
        </template>
        <v-card variant="outlined" class="mt-2 border-gray-tonal">
          <v-list class="mt-2" color="primary" :opened="['AIRPORT', `AIRPORT_` + network.country.toUpperCase()]">
            <template v-for="(places, country_key) in airports">
              <v-list-group :value="`AIRPORT_` + country_key">
                <template v-slot:activator="{ props }">
                  <v-list-item min-width="300" v-bind="props" class="font-medium">
                    {{ $t("countries." + country_key) }}
                  </v-list-item>
                </template>
                <template v-for="place in places">
                  <v-list-item
                    min-width="300"
                    class="pl-0"
                    :to="localeRoute({ name: 'place', params: { place: place.route } })"
                  >
                    <v-list-item-title class="font-semibold">
                      {{ place.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("fields.PARKING_AT_AIRPORT") }}
                    </v-list-item-subtitle>
                    <template #append>
                      <div class="w-20 inline-block font-semibold text-center">
                        <span class="bg-orange px-4 py-1 rounded-full text-sm text-black">{{ place.code }}</span>
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu elevation="3" v-if="Object.keys(stations).length !== 0">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" :color="navbarText" rounded="xl" class="hidden lg:flex">
            <VIcon start :icon="mdiTrainBus" />
            {{ $t("menu.parking_stations") }}
          </v-btn>
        </template>
        <v-card variant="outlined" class="mt-2 border-gray-tonal">
          <v-list class="mt-2" :opened="['STATION', `STATION_` + network.country.toUpperCase()]">
            <template v-for="(places, country_key) in stations">
              <v-list-group :value="`STATION_` + country_key">
                <template v-slot:activator="{ props }">
                  <v-list-item min-width="300" v-bind="props" class="font-medium">
                    {{ $t("countries." + country_key) }}
                  </v-list-item>
                </template>
                <template v-for="place in places">
                  <v-list-item
                    min-width="300"
                    class="pl-0"
                    :to="localeRoute({ name: 'place', params: { place: place.route } })"
                  >
                    <v-list-item-title class="font-semibold">
                      {{ place.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("fields.PARKING_AT_STATION") }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
          </v-list>
        </v-card>
      </v-menu>

      <v-spacer />

      <v-menu elevation="3" width="600">
        <template v-slot:activator="{ props }">
          <v-btn class="hidden md:hidden xl:flex mr-2 rounded-3xl" v-bind="props" :color="navbarText">
            <VIcon start :icon="mdiParking" />
            {{ $t("navbar.contact_button") }}
          </v-btn>
        </template>

        <v-card variant="outlined" class="mt-2 border-gray-tonal">
          <v-card-text class="text-base">
            <v-row>
              <v-col class="text-center self-center">
                {{ $t("navbar.contact_text") }}
                <div class="mt-4">
                  <v-btn color="primary" class="shadow-sm" :to="localeRoute({ name: 'faq' })">
                    {{ $t("navbar.help_center") }}
                  </v-btn>
                </div>
              </v-col>
              <v-col>
                <v-list
                  bg-color="transparent"
                  class="md:pt-0 border-l pl-4"
                  :opened="$vuetify.display.mdAndUp ? ['List'] : []"
                >
                  <v-list-item
                    color="primary"
                    density="compact"
                    class="rounded-3xl mb-1"
                    v-for="(item, index) in [
                      { title: $t('menu.others_howitworks'), route: 'others-howitworks' },
                      { title: $t('menu.others_howtoselect'), route: 'others-howtoselect' },
                      { title: $t('menu.others_whywe'), route: 'others-whywe' },
                      { title: $t('menu.contact'), route: 'contact' },
                    ]"
                    :key="index"
                    :to="localeRoute({ name: item.route })"
                  >
                    {{ item.title }}
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>

      <Locale />

      <v-menu v-if="!(authStore.isLoggedIn && authStore.isCustomer)" elevation="3" width="250">
        <template v-slot:activator="{ props }">
          <v-btn class="hidden md:flex shadow-sm" rounded="3xl" variant="outlined" v-bind="props" :color="navbarText">
            <v-icon class="md:-ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                style="display: block; height: 100%; width: 100%; fill: currentcolor"
              >
                <path
                  d="M16 .7C7.56.7.7 7.56.7 16S7.56 31.3 16 31.3 31.3 24.44 31.3 16 24.44.7 16 .7zm0 28c-4.02 0-7.6-1.88-9.93-4.81a12.43 12.43 0 0 1 6.45-4.4A6.5 6.5 0 0 1 9.5 14a6.5 6.5 0 0 1 13 0 6.51 6.51 0 0 1-3.02 5.5 12.42 12.42 0 0 1 6.45 4.4A12.67 12.67 0 0 1 16 28.7z"
                ></path>
              </svg>
            </v-icon>
            <span class="hidden md:inline pl-2"> {{ $t("navbar.account") }} </span>
          </v-btn>
        </template>
        <v-card variant="outlined" class="mt-2 border-gray-tonal">
          <v-list class="mt-1" density="compact">
            <v-list-item :to="localeRoute({ name: 'bookings-manage' })">
              <v-list-item-title> {{ $t("navbar.booking_manage") }} </v-list-item-title>
              <v-list-item-subtitle v-if="authStore.isLoggedIn && authStore.isBooking">
                {{ authStore.state.user?.name }}
              </v-list-item-subtitle>
              <template #append v-if="authStore.isLoggedIn && authStore.isBooking">
                <v-icon @click="authStore.logout()">
                  <svg width="64" height="64" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M20 12h-9.5m7.5 3 3-3-3-3m-5-2V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h5a2 2 0 0 0 2-2v-1"
                    />
                  </svg>
                </v-icon>
              </template>
            </v-list-item>
            <v-divider class="my-1" />
            <v-list-item :to="localeRoute({ name: 'customer-auth-login', query: { to: route.fullPath } })">
              <v-list-item-title> {{ $t("buttons.login") }} </v-list-item-title>
            </v-list-item>
            <v-list-item :to="localeRoute({ name: 'customer-auth-register' })">
              <v-list-item-title> {{ $t("buttons.register") }} </v-list-item-title>
              <v-list-item-subtitle> {{ $t("navbar.customer_subtitle") }} </v-list-item-subtitle>
            </v-list-item>
            <!-- 
          <v-divider class="my-1" />
          <v-list-item :to="localeRoute({ name: 'index' })">
            <v-list-item-title> Travel Agency </v-list-item-title>
          </v-list-item>
          <v-list-item :to="localeRoute({ name: 'index' })">
            <v-list-item-title> Add your parking </v-list-item-title>
          </v-list-item>
          -->
          </v-list>
        </v-card>
      </v-menu>

      <v-menu v-if="authStore.isLoggedIn && authStore.isCustomer" elevation="3" width="250">
        <template v-slot:activator="{ props }">
          <v-btn
            class="hidden md:flex border-gray-tonal"
            rounded="3xl"
            variant="outlined"
            v-bind="props"
            :color="navbarText"
          >
            <v-icon start class="md:-ml-1">
              <v-avatar size="x-small" class="bg-white">
                <img :src="authStore.state?.user?.avatar" alt="avatar" />
              </v-avatar>
            </v-icon>
            <span class="hidden md:inline truncate max-w-20"> {{ authStore.state?.user?.name }} </span>
          </v-btn>
        </template>
        <v-card variant="outlined" class="mt-2 border-gray-tonal">
          <v-list class="mt-1" density="compact">
            <v-list-item class="text-gray">
              {{ $t("navbar.welcome", { name: authStore.state?.user?.name }) }}
            </v-list-item>
            <v-divider class="my-1" />
            <v-list-item :to="localeRoute({ name: 'customer-bookings' })">
              <v-list-item-title class="font-semibold"> {{ $t("menu.customer_bookings") }} </v-list-item-title>
            </v-list-item>
            <v-list-item :to="localeRoute({ name: 'customer-settings' })">
              <v-list-item-title> {{ $t("navbar.account") }} </v-list-item-title>
            </v-list-item>
            <v-divider class="my-1" />
            <v-list-item :to="localeRoute({ name: 'faq' })">
              <v-list-item-title> {{ $t("navbar.help_center") }} </v-list-item-title>
            </v-list-item>
            <v-list-item @click="authStore.logout()">
              <v-list-item-title class="opacity-80"> {{ $t("buttons.logout") }} </v-list-item-title>
            </v-list-item>
            <!-- 
          <v-divider class="my-1" />
          <v-list-item :to="localeRoute({ name: 'index' })">
            <v-list-item-title> Travel Agency </v-list-item-title>
          </v-list-item>
          <v-list-item :to="localeRoute({ name: 'index' })">
            <v-list-item-title> Add your parking </v-list-item-title>
          </v-list-item>
          -->
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>

  <Sidebar v-model="sidebar" />
</template>
